<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-tabs content-class="mt-3" v-model="tabIndex" @activate-tab="tabChanged">
                <b-tab title="社内手順書" id="manual">
                  <b-list-group>
                    <b-list-group-item href="/manual/2-2 各証明書の発行.pdf" target="_blank">
                      証明書発行（在職証明、所得証明、ビザ更新）
                    </b-list-group-item>
                    <b-list-group-item href="/manual/3-4 勤怠管理システム操作マニュアル.pdf" target="_blank">
                      勤怠管理システム操作マニュアル
                    </b-list-group-item>
                    <b-list-group-item href="/manual/3-9 通勤定期代申請手順.pdf" target="_blank">
                      通勤定期代申請手順
                    </b-list-group-item>
                    <b-list-group-item href="/manual/3-6 定期券申請と立替経費精算の違い.pdf" target="_blank">
                      定期券申請と立替経費精算の違い
                    </b-list-group-item>
                    <b-list-group-item href="/manual/3-8 固定残業時間変更及び残業代計算方法のご案内.pdf" target="_blank">
                      固定残業時間変更及び残業代計算方法のご案内
                    </b-list-group-item>
                    <b-list-group-item href="/manual/4-1 給与明細、源泉徴収票確認手順.pdf" target="_blank">
                      給与明細、源泉徴収票確認手順
                    </b-list-group-item>
                    <b-list-group-item href="/manual/5-1 会議室予約方法及び利用規則.pdf" target="_blank">
                      会議室予約方法及び利用規則
                    </b-list-group-item>
                    <!-- <b-list-group-item href="/manual/21-3 健康診断の実施方法.pdf" target="_blank">
                      健康診断の実施方法
                    </b-list-group-item> -->
                  </b-list-group>
                </b-tab>
                <b-tab title="各申請書フォーマット" id="format">
                  <b-list-group>
                    <b-list-group-item href="/manual/①【現場出勤・帰社用】_立替交通費精算書フォーマット_20220401時点.xlsx" target="_blank">
                      ①【現場出勤・帰社用】_立替交通費精算書フォーマット
                    </b-list-group-item>
                    <b-list-group-item href="/manual/②【営業目的】_立替経費精算書フォーマット_20220401時点.xlsx" target="_blank">
                      ②【営業目的】_立替経費精算書フォーマット
                    </b-list-group-item>
                    <b-list-group-item href="/manual/③【客先立替】_立替経費精算書フォーマット_20220401時点.xlsx" target="_blank">
                      ③【客先立替】_立替経費精算書フォーマット
                    </b-list-group-item>
                    <b-list-group-item href="/manual/③立替経費精算についてのご説明_20220401時点.pdf" target="_blank">
                      ③立替経費精算についてのご説明
                    </b-list-group-item>
                    <b-list-group-item href="/manual/④休暇取消・変更届.xls" target="_blank">
                      ④休暇取消・変更届
                    </b-list-group-item>
                    <b-list-group-item href="/manual/④遅刻・早退・外出・有給・欠勤届.xls" target="_blank">
                      ④遅刻・早退・外出・有給・欠勤届
                    </b-list-group-item>
                    <b-list-group-item href="/manual/⑤残業・休日出勤申請書.docx" target="_blank">
                      ⑤残業・休日出勤申請書
                    </b-list-group-item>
                    <b-list-group-item href="/manual/⑥休職届＆復職願.xlsx" target="_blank">
                      ⑥休職届＆復職願
                    </b-list-group-item>
                    <b-list-group-item href="/manual/⑦健康保険・厚生年金被扶養者（異動）情報.xlsx" target="_blank">
                      ⑦健康保険・厚生年金被扶養者（異動）情報
                    </b-list-group-item>
                  </b-list-group>
                </b-tab>
              </b-tabs>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { common } from '../../utils';

export default {
  data() {
    return {
      tabIndex: 0,
    };
  },
  created: function () {
    document.title = common.getPageName(this.$route.name);
  },
  mounted() {
    setTimeout(this.setCurrentTab, 500);
  },
  methods: {
    setCurrentTab() {
      const activeId = this.$route.hash.slice(1)
      if (activeId) {
        const tabs = this.getTabsId();
        const currIndex = tabs.indexOf(activeId);
        this.tabIndex = currIndex; 
      }
    },
    tabChanged(newTabIndex) {
      const tabs = this.getTabsId();
      const currTabId = tabs[newTabIndex];
      const newUrl = `${this.$route.path}#${currTabId}`;
      if (this.$route.fullPath !== newUrl) {
        this.$router.push(newUrl)
      }
    },
    getTabsId() {
      return Array.from(document.querySelectorAll('div.tab-pane')).map(i => i.id);
    }
  },
}
</script>